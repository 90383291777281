import React from "react";
import Layout from "../../components/layouts/layout";
import { BASE_SITE_URL } from "../../shared/constants";

const TermsAndConditions = () => {
  return (
    <Layout
      seo={{
        title: "Terms and Conditions",
        ogtitle: "Terms and Conditions",
        description: "Welcome to Terms and Conditions Page",
        ogurl: `${BASE_SITE_URL}/terms-and-conditions`,
      }}
    >
      <div className="mt-70">.</div>
      <div className="mt-70 body-container">
        <div className="title-header">
          {" "}
          <span className="red-line3letter">TER</span>MS AND CONDITIONS{" "}
        </div>
      </div>
      {/* <div className="mt-70 body-container">
        <div className="mini-view-title">Updated 30 September 2020</div>
      </div> */}
      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">1. D</span>EFINITIONS
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>1.1 </b>In these Conditions, the following words and expressions
          shall have the meaning set out below:
        </div>
        <div className="article-main">
          Agreement means these Conditions read in conjunction with the
          Specification and Letter of Engagement.
        </div>
        <div className="article-main">
          Client means the individual or business named on the Specification
          attached.
        </div>
        <div className="article-main">
          Digiryte is a trading name of Digiryte limited (Company No:10189433).
        </div>
        <div className="article-main">
          Project means described by the Specification and governed by this
          Agreement.
        </div>
        <div className="article-main">
          Specification means the Project details in or annexed to the Letter of
          Engagement.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>1.2 </b>The construction, validity and performance of these
          Conditions and this order shall be governed by the laws of England and
          Wales.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>1.3 </b>All services provided by Digiryte to the Client shall be
          governed by these Conditions not withstanding other terms stipulated
          at the point of order.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>1.4 </b>If there is any conflict between these Conditions and any
          other agreement made between Digiryte and the Client, these Conditions
          shall prevail.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">2. P</span>ROJECTS DEAL AND
          DEVELOPMENT
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.1 </b>All specifications and conditions are drafted in the Letter
          of Engagement with respect to the assigned project.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.2 </b>Prices are stated in the Letter of Engagement provided by
          Digiryte are valid for 30 days of the date specified in the drafted
          agreement. Digiryte reserve the right to withdraw the terms prior to
          approval by the Client.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.3 </b>Projects' will only be developed, based on the client's
          specfications defined in the Letter of Engagement
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.4 </b>Terms will not be deemed accepted until Digiryte receive
          the Letter of Engagement and the Specification signed by the Client.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.5 </b>Subject to payment of the Fees and the provision of client
          content by the Client, Digiryte agrees to design and implement the
          Project using reasonable endeavours and in accordance with the
          Specification, the Letter of Engagement and the other provisions of
          this Agreement.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.6 </b>Digiryte shall notify the Client when the Project or any
          agreed part is available for approval testing. The Client shall advise
          Digiryte of any errors in that version of the Project within 5 working
          days of notification by e-mail. Digiryte will use reasonable
          endeavours to correct such errors.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.7 </b>If the Client notifies Digiryte of any amendments to the
          original specification, the provisions of clause 3 shall apply.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.8 </b>If the Client does not notify Digiryte of any errors within
          5 working days of the Project being available for acceptance testing,
          the Project will be deemed to have been completed to a satisfactory
          standard and payment will be due in accordance with clause 4.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>2.9 </b>Digiryte reserve the right to assign subcontractors to
          complete part or the whole of the Project.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">3. I</span>MPROVISED SPECIFICATION
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>3.1 </b>Any improvisations to the Specification must be sent to
          Digiryte by the Client in writing by e-mail. Digiryte will assess the
          amendments required and discuss with the client an improvised
          Specification.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>3.2 </b>Digiryte reserves the right to charge for any additional
          work and will provide the Client with an additional quotation as part
          of the improvised Specification. Digiryte also reserve the right to
          request payment for amendments to the original Specification before
          continuing work.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>3.3 </b>The Client will be required to agree the improvised
          Specification in writing by email before work on the amendments can
          commence.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>3.4 </b>If the Client does not return the signed improvised
          Specification to Digiryte within 5 working days, the Client shall be
          allowed not to require such amendments and the Project shall be
          assumed, complete with payment due in accordance with clause 4.
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">4. P</span>AYMENT
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>4.1 </b>The Client shall pay a deposit as requested by Digiryte in
          the Letter of Engagement. Digiryte will not commence any work on the
          Project until deposit monies are received by Digiryte.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>4.2 </b>The balance of the price shall become due at the completion
          of the Project (or deemed completion in accordance with clauses 2 or
          3).
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>4.3 </b>All invoices must be settled within 30 days of the invoice
          date. Payment may be made by cash, cheque (made payable to Digiryte
          Ltd) or bank transfer.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>4.4 </b>All prices quoted for work are subject to taxable charges
          at the prevailing rate.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>4.5 </b>Returned cheques will incur an additional fee of £50 per
          returned cheque. Digiryte reserves the right to consider an account in
          default in the event of a returned cheque.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>4.6 </b>Payment can also be made through Digiryte's Payment gateway
          portal.
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">5. O</span>UTSTANDING PAYMENTS
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>5.1 </b>Digiryte reserves the right to refuse to undertake any
          further work for the Client if an invoice remains outstanding.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>5.2 </b>Digiryte reserves the right to remove any work done for a
          Project from any computer systems and the internet if invoice payments
          are not received within 30 days of the invoice date.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>5.3 </b>If payment is not received within 30 days of the invoice
          date, interest will be charged at 8% above the base rate of the Bank
          of England and will continue to be charged on a daily basis until
          payment is received in full.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>5.4 </b>Digiryte’s removal of Project material does not relieve the
          Client of its obligation to pay the due amount. Clients whose accounts
          are in default agree to pay Digiryte Ltd’s reasonable legal expenses
          and third party collection agency fees in the enforcement of these
          Conditions.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">6. C</span>LIENT OBLIGATIONS
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          The Client complies with the following conditions :
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.1 </b>Digiryte will not be liable for the Client’s failure to
          comply with any laws or taxes affecting e-commerce.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.2 </b>The resale or distribution of the Project in full or part
          is forbidden unless prior written agreement is made between the Client
          and Digiryte.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.3 </b>Digiryte may include developmental credits and links within
          any code, designs, builds or amends.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.4 </b>Digiryte may include any work done for the Client within
          their portfolio of work.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.5 </b>Client has to provide any information or content required
          by Digiryte, promptly. Failure to provide required information or
          content within 5 working days of request will result in payment being
          due for the work done in accordance with clause 4 and Digiryte will
          not be liable for any failure to subsequently meet targeted dates for
          completion of any related task.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.6 </b>If a problem with the design or code arises which does not
          allow the original Specification to be met, Digiryte may apply the
          nearest available alternative solution.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.7 </b>Client has to keep all passwords confidential at all times.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.8 </b>Clients are responsible for providing and maintaining
          suitable equipment telecommunications and support services to
          facilitate access to the Project.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>6.9 </b>If a choice of design is presented by the Client, only one
          solution is deemed to be given by Digiryte as fulfilling the Project.{" "}
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">7. W</span>ARRANTIES
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>7.1 </b>The Client warrants that it has the authority to enter into
          and perform this Agreement and has not entered into any other
          agreement which conflicts with this Agreement or inhibits, restricts
          or impairs its ability to perform its obligations under this
          Agreement.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>7.2 </b>The Client warrants that it has obtained and will maintain
          all necessary licences, authorisations and consents which are
          necessary for the Project, content and domain name.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">8. C</span>OPYRIGHT AND OWNERSHIP
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.1 </b>All design and development work carried out by Digiryte for
          the client will become the property of the client on completion of the
          project and payment of all outstanding invoices.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.2 </b>Data which is gathered as a consequence of the operation of
          the website shall belong to and be the responsibility of the Client.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.3 </b>Where the Client terminates the Project before completion,
          all work done towards the Project remains in the Ownership of
          Digiryte.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.4 </b>Publication and/or release of the graphic design within the
          Project may not take place before cleared funds have been received by
          Digiryte Ltd.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.5 </b>The Client may request, in writing, Digiryte’s permission
          to use Project material (for which Digiryte hold the copyright) in
          forms other than for which it was originally supplied. Digiryte may,
          in its absolute discretion, grant this and may charge for the
          provision of the same.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.5 </b>If the client supplies material to Digiryte, it is the
          responsibility of the client to obtain all necessary copyrights for
          its use and Digiryte will assume this has been done. In this
          situation, the copyright shall be retained by the client.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.7 </b>Should the Client supply material to Digiryte believing it
          to be copyright and royalty free, which subsequently emerges to have
          copyright and royalty limitations, the Client agrees to permit
          Digiryte to remove and/or replace the file at the Client’s expense to
          be assessed on a quantum meruit basis. In this situation, the client
          indemnifies Digiryte from any claim which arises regarding the use of
          material supplied to Digiryte.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.8 </b>The Client agrees that Digiryte hold no responsibility for
          any amendments made by a third party before or after a design is
          published.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>8.9 </b>Digiryte reserve the right to use any artwork or printing
          they produce for the purposes of promoting their services.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">9. A</span>GREEMENT
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>9.1 </b>The Agreement shall commence upon the date of signature on
          the Letter of Engagement and shall continue until receipt of invoice
          payment, unless terminated in accordance with clause 14.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>9.2 </b>Termination shall be without prejudice to any rights
          acquired by the parties during the term of the contract.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">10. C</span>OMPENSATIONS
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>10.1 </b>The Client agrees to keep Digiryte fully indemnified on
          demand against any liability, damage, expense, claim or cost
          (including legal costs and expenses) suffered by Digiryte as a result
          of the Client’s breach of any clause of this Agreement.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>10.2 </b>The Client agrees that Digiryte is not liable for any
          claims, losses, costs incurred or damages due to any failure to carry
          out services within a given delivery timescale.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>10.3 </b>The Client agrees that Digiryte is not liable for absence
          of service as a result of illness or holiday.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>10.4 </b>The Client agrees that Digiryte shall not be liable for
          the website content, hosting and choice of domain name.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>10.5 </b>The Client agrees that Digiryte shall not be liable for
          any infringement of copyright or proprietary rights, misinformation or
          delivery of defective products or services.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>10.6 </b>The Client will indemnify Digiryte in relation to any
          liability arising in respect of the matters at 10.2, 10.4 and 10.5.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">11. L</span>IMITATION OF LIABILITY
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>11.1 </b>Notwithstanding anything contained in these Conditions or
          the Specification, Digiryte’s liability to the Client in respect of
          the Project, in contract, tort (including negligence or breach of
          statutory duty) or howsoever otherwise arising, shall be limited to
          the price for the Project specified in the Letter of Engagement.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>11.2 </b>Notwithstanding anything contained in these Conditions or
          the Specification, in no circumstances shall Digiryte be liable, in
          contract, tort (including negligence or breach of statutory duty) or
          otherwise howsoever, and whatever the cause thereof (i) for any loss
          of profit, business, contracts, revenues or anticipated savings, or
          (ii) for any special indirect or consequential damage of any nature
          whatsoever.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">12. F</span>ORCE MAJEURE
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>12.1 </b>Digiryte shall not be under any liability for any failure
          to perform any of its obligations under the order due to ‘Force
          Majeure’. Following notification by Digiryte to the Client of such
          cause, Digiryte shall be allowed a reasonable extension of time for
          the performance of its obligations.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>12.2 </b>For the purposes of this condition, ‘Force Majeure’ means
          fire, explosion, flood, lightening, Act of God, act of terrorism, war,
          rebellion, riot, sabotage, telecommunication problems, software
          failure, hardware failure, third party interference, official strike
          or similar official labour dispute, or events or circumstances outside
          the reasonable control of the party affected thereby.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">13. C</span>ONFIDENTIAL TERMS
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>13.1 </b>The Client is responsible for keeping the password and
          username that may be assigned on registration confidential. The Client
          is fully responsible for all action and activities that take place
          under the Client account.{" "}
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>13.2 </b>If the Client believes there has been unauthorised use of
          the account or suspects that confidentiality has been compromised, the
          Client must contact Digiryte immediately.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>13.3 </b>In the event of termination or expiration of this
          Agreement, each party shall return or, if requested, destroy the
          confidential information of that party.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>13.4 </b>Each party will comply with its obligations pursuant to
          the Data Protection Act 1998.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>13.5 </b>Digiryte and any third party associates agree that it will
          not disclose any confidential information relating to the Client
          without the Client’s express permission.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>13.6 </b>The Client agrees that it will not disclose any
          confidential information relating to Digiryte.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>13.7 </b>Digiryte shall use information provided by the Client: (a)
          to identify the Client in communication by phone, email, whatsapp or
          Skype; and (b) to contact the Client from time to time to offer them
          services or products which may be of interest to or benefit the
          Client.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">14. T</span>ERMINATION
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>14.1 </b>The Client may request in writing that Digiryte cancel a
          Project. Digiryte will only accept this request for termination if
          work on the Project has not yet begun. If work has already begun on
          the Project, Digiryte will invoice the Client for the work carried
          out.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>14.2 </b>Digiryte reserves the right not to work with any Client
          who has a site which it deems is unlawful or inappropriate, contains a
          virus or hostile program, constitutes harassment, racism, violence,
          obscenity, harmful intent, spamming, contains adult content, commits a
          criminal offence, infringes privacy or copyright or any other
          questionable media at its own discretion.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>14.3 </b>Digiryte reserves the right without notice to cancel,
          reject, refuse sale to or work with a Client without reason for such
          rejection or refusal.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>14.4 </b>Digiryte reserve the right to cancel the Client’s email
          access: (a) if Digiryte receive excessive spam complaints about the
          Client (b) it is suspected the Client is using the email account to
          send spam (c) Digiryte suspect the Client is using the service to send
          pornographic, offensive or inappropriate material (d) Invoice payment
          is not made in accordance with these terms.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>14.5 </b>Should the Client go into compulsory or involuntary
          liquidation or cannot pay its debts in the normal course of business,
          Digiryte reserves the right terminate the Project.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>14.6 </b>Digiryte reserves the right to terminate a Project with
          the Client at any time without prior notification if it deems the
          Client to be in breach of any clause of this agreement. Digiryte shall
          be the sole arbiter in deciding what constitutes a breach. The Client
          shall not be entitled to refunds of any payments made to Digiryte.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>14.7 </b>If the Project is terminated by either party then Digiryte
          will be entitled to payment for work done to that date. The invoice
          for such work must be paid by the Client within 30 days of receipt
          failing which Digiryte shall be entitled to payment of the full price
          of the Project forthwith.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">15. W</span>EB AND MOBILE
          APPLICATION - DESIGN AND DEVELOPMENT
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.1 </b>The web or mobile designs offered by Digiryte can be used
          only on one domain. The Client is not permitted to use a design on
          more than one web or mobile application applicable without prior
          written agreement from Digiryte.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.2 </b>Once client received designs from Digiryte, client will
          have 10 days to respond and request changes. If a request or response
          is not received within this time period, the design shall be
          considered approved and Digiryte shall proceed to the integration
          stage following receipt of any payments due. The same 10 day rule
          applies after each update following an amendment request.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.3 </b>Once integration is complete, the client will have 10 days
          to respond and request further changes. If a request or response is
          not received within this time period, the project will be considered
          complete and Digiryte shall proceed to the Go Live process following
          receipt of any payments due at this stage. The same 10 day rule
          applies after each update following an amendment request. If details
          required by Digiryte to publish the site/app to the web have not been
          provided by the client at this stage, the entire code of the
          web/mobile application will be made available to the client for
          download and the project will be considered delivered. The client will
          have a further 10 days from this point to download their copy of the
          code before the download links are removed.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.4 </b>Digiryte cannot guarantee the working of web/mobile
          applications on all versions of browsers, all versions of operating
          systems, all screen sizes, all versions of web or mobile application
          development platforms, frameworks and libraries.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.5 </b>The Client agrees that all web/mobile applications will be
          hosted by Digiryte unless otherwise agreed in the Specification and
          client also agrees that any third party paid or unpaid packages and
          middlewares used to develop the web and mobile applications may stop
          working any time during the development and post development. Client
          also acknowledges that these impacts are not within Digiryts’s control
          and considered as out of scope.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.6 </b>Digiryte may from time to time recommend to the Client
          that updates are needed to their web/mobile applications to comply
          with, but not limited to, the following: new legislation, software
          releases, frameworks or library package version upgrades. Digiryte
          reserves the right to charge for these updates as additional work.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.7 </b>The Client agrees that no liability will be attributable
          to Digiryte in the event of web/mobile application downtime or the
          inability to operate the web/mobile applications due to any error in
          the code or others.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>15.8 </b>Should the Client supply material to Digiryte believing it
          to be copyright and royalty free, which subsequently emerges to have
          copyright and royalty limitations, the Client agrees to permit
          Digiryte to remove and/or replace the file at the Client’s expense to
          be assessed on a quantum meruit basis.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">16. H</span>OSTING, MAINTENANCE AND
          SUPPORT
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>16.1 </b>The Client agrees that Digiryte is not liable for any
          bugs, performance issues or failure of their code in any environment
          such as development, testing, staging, pre-production or production.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>16.2 </b>The Client agrees that no liability will be attributable
          to Digiryte in the event of web/mobile application downtime or the
          inability to operate the web/mobile applications due to any error in
          the code or others.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>16.3 </b>The Client agrees that Digiryte or its agents will not be
          liable if they fail to register such domain names as requested by the
          Client. Digiryte and its agents will use its reasonable endeavours to
          ensure such registration.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>16.4 </b>Digiryte will quote for any work involved in changing the
          web/mobile application design or code in order for it to work with
          updated browsers, domain name, hosting, version changes of operating
          systems, programming languages and its supporting frameworks or
          libraries.
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>16.5 </b>Digiryte will charge the client for any additional effort
          or cost involved in development due to upgrades of software,
          framework, librarie, server maintenance, hosting, spec upgrade and any
          other changes as such.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">17. R</span>ELATIONSHIP
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>17.1 </b>Nothing in this Agreement shall be deemed to create a
          partnership or joint venture or contract of employment of any kind
          between the parties.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">18. E</span>NTIRE AGREEMENT
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>18.1 </b>Each party agrees that this Agreement sets out the entire
          agreement between the parties and supersedes all previous agreements.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">19. W</span>AIVER
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          The failure by Digiryte to enforce any of these Conditions at any time
          or for any period will not release or exonerate or in any way affect
          the liability of the Client or be a waiver of:
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>19.1 </b>the right of Digiryte at any time afterwards to enforce
          each and every clause of these Conditions; or
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>19.2 </b>any penalty attached to their performance.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">20. A</span>GREEMENT SEVERENCE
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>20.1 </b>If any provision of this agreement is prohibited by law or
          judged by a court to be unlawful, void or unenforceable, the provision
          shall, to the extent required, be severed from this agreement and
          rendered ineffective as far as possible without modifying the
          remaining provisions of this agreement, and shall not in any way
          affect any other circumstances of or the validity or enforcement of
          this agreement.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">21. T</span>IME
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>21.1 </b>Throughout the Project, time shall not be of the essence,
          except where it is expressly stated to apply.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">22. T</span>HIRD PARTY RIGHTS
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>22.1 </b>Pursuant to s 1(2)(a) of the Contracts (Rights of Third
          Parties) Act 1999 the parties intend that no term of this agreement
          may be enforced by any person who is not a party to this agreement.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">23. A</span>SSIGNMENT
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>23.1 </b>This Agreement shall be binding upon the parties and their
          respective successors and permitted assignees, and references to a
          party in this agreement shall include its successors and permitted
          assignees.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">24. G</span>OVERNING LAW
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div className="article-main">
          <b>24.1 </b>This Agreement shall be governed by and interpreted in
          accordance with English law and the English courts shall have
          jurisdiction to resolve any disputes between the parties.
        </div>
      </div>

      {/* Terms and Conditions Version List */}
      <div className="mt-30 body-container">
        <div className="article-main">
          <span className="red-line3letter">Ter</span>ms and conditions version
          directory
        </div>
        <ul>
          <li>
            <a
              rel="noreferrer"
              className="atagclass"
              target="_blank"
              href="https://www.digiryte.com/terms-and-conditions/v1.1"
            >
              v1.1 - Before 30th August 2019
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              className="atagclass"
              target="_blank"
              href="https://www.digiryte.com/terms-and-conditions/v1.2"
            >
              v1.2 - 30th August 2019 - 2nd August 2021
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              className="atagclass"
              target="_blank"
              href="https://www.digiryte.com/terms-and-conditions/v1.3"
            >
              v1.3 - Updated on 3rd August 2021
            </a>
          </li>
        </ul>
      </div>

      <div className="mt-30 ">
        <hr
          style={{ border: "1px solid #FAFAFA", background: "transparent" }}
        />
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
